<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myorder' }">{{$t('message.myorder')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myorderinfo',query:{order_no:orderCode}}">{{$t('message.order')}}：{{orderCode}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.seelog')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【物流信息】 -->
        <b-container class="pc-shopcar">
            <div class="pc-shopwrap">
                <div class="pc-shop">
                    <div class="pc-title">{{$t('message.loginfo')}}</div>
                    <div class="pc-info">
                        <div class="pc-label">{{$t('message.shoshopinfo')}}</div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.shoppeple')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.user_name}} +{{languageCodeObj&&languageCodeObj.mobile_area_code}} {{orderInfoObj.mobile}}</div>
                        </div>
                        <!-- <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.shoaddress')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.info}}，{{orderInfoObj.country}}，{{orderInfoObj.city}},{{orderInfoObj.apartment}}</div>
                        </div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.doorling')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.doorbell}}</div>
                        </div> -->
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.postcode')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.postcode}}</div>
                        </div>
                    </div>
                    <div class="pc-info">
                        <div class="pc-label">{{$t('message.fhinfo')}}</div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.logisnum')}}：</div>
                            <div class="pc-txt">{{sendoutinfo.parcelNumber}}</div>
                        </div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.logcompay')}}：</div>
                            <div class="pc-txt">{{sendoutinfo.logistics_company}}</div>
                        </div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.fpople')}}：</div>
                            <div class="pc-txt">{{sendoutinfo.consignee_name}}</div>
                        </div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.fadress')}}：</div>
                            <div class="pc-txt">{{sendoutinfo.it_refund_address}}</div>
                        </div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.postcode')}}：</div>
                            <div class="pc-txt">{{sendoutinfo.consignee_postcode}}</div>
                        </div>
                    </div>
                </div>
                <div class="pc-all">
                    <div class="pc-title">{{$t('message.logising')}}</div>
                    <div class="pc-log">
                        <div class="pc-log-step">
                            <van-steps direction="vertical" :active="0">
                                <van-step class="pc-log-box" v-for="(item,index) in logisDataList" :key="index">
                                    <div class="pc-time">{{item.timedata}}</div>
                                    <div class="pc-txt">
                                        <div class="pc-txt-title">{{item.descrizione}} - {{item.filiale}}</div>
                                        <div class="pc-txt-txt">{{item.descrizione}} - {{item.filiale}}</div>
                                    </div>
                                </van-step>
                            </van-steps>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
        <!-- wap布局【物流信息】 -->
        <b-container class="wap-shopcar">
            <b-row class="wap-info-shop">
                <div class="wap-info-title">{{$t('message.orddetail')}}</div>
                <div class="wap-info-box">
                    <div class="wap-info-label">{{$t('message.shoshopinfo')}}</div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.shoppeple')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.user_name}} +{{languageCodeObj&&languageCodeObj.mobile_area_code}} {{orderInfoObj.mobile}}</div>
                    </div>
                    <!-- <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.shoaddress')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.info}}，{{orderInfoObj.country}}，{{orderInfoObj.city}},{{orderInfoObj.apartment}}</div>
                    </div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.doorling')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.doorbell}}</div>
                    </div> -->
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.postcode')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.postcode}}</div>
                    </div>
                </div>
                <div class="wap-info-box">
                    <div class="wap-info-label">{{$t('message.fhinfo')}}</div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.logisnum')}}：</div>
                        <div class="wap-info-txt">{{sendoutinfo.parcelNumber}}</div>
                    </div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.logcompay')}}：</div>
                        <div class="wap-info-txt">{{sendoutinfo.logistics_company}}</div>
                    </div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.fpople')}}：</div>
                        <div class="wap-info-txt">{{sendoutinfo.consignee_name}}</div>
                    </div>
                    <div class="wap-info-list" >
                        <div class="wap-info-name">{{$t('message.fadress')}}：</div>
                        <div class="wap-info-txt">{{sendoutinfo.it_refund_address}}</div>
                    </div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.postcode')}}：</div>
                        <div class="wap-info-txt">{{sendoutinfo.consignee_postcode}}</div>
                    </div>
                </div>
            </b-row>
            <b-row class="wap-info-logis">
                <div class="wap-info-tit">{{$t('message.logising')}}</div> 
                <div class="wap-log">
                    <div class="wap-log-step">
                        <van-steps direction="vertical" :active="0">
                            <van-step class="wap-log-box" v-for="(item,index) in logisDataList" :key="index">
                                <div class="wap-time">{{item.timedata}}</div>
                                <div class="wap-txt">
                                    <div class="wap-txt-title">{{item.descrizione}} - {{item.filiale}}</div>
                                    <div class="wap-txt-txt">{{item.descrizione}} - {{item.filiale}}</div>
                                </div>
                            </van-step>
                        </van-steps>
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>

import ElementUI from 'element-ui';
import {blurRule,config,setLocalItem,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'logistics',
    components:{
    },
    data(){
        return{
            orderCode:'',
            payStateShow:6,
            languageCodeObj:{},
            orderInfoObj:{},
            sendoutinfo:{},
            logisDataList:[],
            logisData:[
                {
                    time:'2022-05-20 12:00',
                    title:'快件已离开了DHL转运网点 MILAN - ITALY',
                    txt:'MILAN - MALPENSA - ITALY'
                },{
                    time:'2022-05-20 12:00',
                    title:'快件已离开了DHL转运网点 MILAN - ITALY',
                    txt:'MILAN - MALPENSA - ITALY'
                },{
                    time:'2022-05-20 12:00',
                    title:'快件已离开了DHL转运网点 MILAN - ITALY',
                    txt:'MILAN - MALPENSA - ITALY'
                },{
                    time:'2022-05-20 12:00',
                    title:'快件已离开了DHL转运网点 MILAN - ITALY',
                    txt:'MILAN - MALPENSA - ITALY'
                },{
                    time:'2022-05-20 12:00',
                    title:'快件已离开了DHL转运网点 MILAN - ITALY',
                    txt:'MILAN - MALPENSA - ITALY'
                },{
                    time:'2022-05-20 12:00',
                    title:'快件已离开了DHL转运网点 MILAN - ITALY',
                    txt:'MILAN - MALPENSA - ITALY'
                },{
                    time:'2022-05-20 12:00',
                    title:'快件已离开了DHL转运网点 MILAN - ITALY',
                    txt:'MILAN - MALPENSA - ITALY'
                }
            ]
        }
    },
    watch:{
        
    },
    computed:{
       
    },
    methods: {
        // 获取订单详情
        getOrderInfo(token,order_no){
            this.$http.api_order_getorderinfo({
                token:token,
                orderno:order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.orderInfoObj = res.data;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    }
                }
            });
        },
        // 获取物流详情
        getlogInfo(token,order_no){
            this.$http.api_order_gettracking({
                token:token,
                orderno:order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            let lista_eventi = res.data[0].datalist.lista_eventi;
                            lista_eventi.map((v,k)=>{
                                v.timeyear = v.data.split('.').reverse().join('-');
                                v.timeora = v.ora.split('.').join(':')
                                v.timedata = v.timeyear+' '+v.timeora
                            })
                            this.logisDataList = res.data[0].datalist.lista_eventi;
                    //         let data = {
                    //             "parcelID": "包裹单号",
                    //             "msg":"返回信息",
                    //             "datalist": [
                    //                 {
                    //                     "lista_eventi":[
                    //                         {
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         },{
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         }
                    //                     ],
                    //                     "lista_note":[
                    //                         {
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         },{
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         }  
                    //                     ]
                    //                 },{
                    //                     "lista_eventi":[
                    //                         {
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         },{
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         }
                    //                     ],
                    //                     "lista_note":[
                    //                         {
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         },{
                    //                             "data":"data",
                    //                             "ora":"ora",
                    //                             "id":"id",
                    //                             "descrizione":"descrizione",
                    //                             "filiale":"filiale"
                    //                         }  
                    //                     ]
                    //                 }
                    //             ]
                    //         }
                    //         // [
                    //         //     {
                    //         //         "parcelID": "089089040900036081",
                    //         //         "msg": "PARCEL NOT FOUND",
                    //         //         "datalist": []
                    //         //     }
                    //         // ]
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    }
                }
            });
        },
        // 获取发货人和收货人信息
        getLogMessage(token,order_no){
            this.$http.api_order_getlogisticsinfo({
                token:token,
                orderno:order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.sendoutinfo = res.data.sendoutinfo;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    }
                }
            });
        }
    },
    mounted(){
        let that = this;
        that.$nextTick(()=>{
            that.languageCodeObj = this.$store.state.data.languageNameStatus;
        });
    },
    created(){
        if(this.$route.query&&this.$route.query.order_no){
            let orderCode = this.$route.query.order_no;
            this.orderCode = orderCode;
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            let routes = this.$route.query;//地址栏参数
            if(routes){
                this.getOrderInfo(token,routes.order_no);// 获取订单详情
                this.getlogInfo(token,routes.order_no);//获取物流详情
                this.getLogMessage(token,routes.order_no)
            }
        }else{
            // ElementUI.Message({
            //     message:this.languageObj.logintip,
            //     type:'error',
            //     center: true,
            //     offset:10,
            // });
            // this.$store.commit('setLoginStatus',true);
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.van-step__icon--active, .van-step__icon--finish, .van-step__title--active, .van-step__title--finish{
    background: transparent;
}
/deep/.van-icon-checked:before {
    content: '';
    height: 10px;
    width: 10px;
    background: #1f73b7;
    border-radius: 100%;
}
/deep/.van-step__circle{
    height: 10px;
    width: 10px;
}
.navtab{
    padding:20px 10px 10px;
    
}
.shopintab{
    padding:0;
}
.pc-shopcar{
    padding:20px 0;
    .pc-shopwrap{
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        border:1px solid #dde0ed;
        .pc-shop{
            width: 40%;
            background: #f4f7fe;
            padding:20px 20px 40px;
            .pc-title{
                font-size:20px;
                padding:10px;
                border-bottom: 1px solid #dde0ed;
            }
            .pc-info{
                padding:15px 20px 10px;
                .pc-label{
                    
                    padding:0px 0 10px;
                }
                .pc-info-list{
                    padding:5px 0;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .pc-name{
                        width:25%;
                        color:#6e6e74;
                    }
                    .pc-txt{
                        width:75%;
                    }
                }
            }
        }
        .pc-all{
            width: 60%;
            padding:20px 60px 40px;
            .pc-title{
                font-size:20px;
                padding:10px;
                border-bottom: 1px solid #dde0ed;
            }
            .pc-log{
                display: flex;
                -webkit-box-orient: horizontal;
                background: darkcyan;
                max-height:400px;
                overflow-y: auto;
                .pc-log-step{
                    width:100%;
                    .pc-log-box{
                        display: flex;
                        -webkit-box-orient: horizontal;
                        /deep/.van-step__title{
                            width:100%;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            .pc-time{
                                width:25%;
                                color: #95959e;
                            }
                            .pc-txt{
                                width:75%;
                                .pc-txt-title{
                                    padding:0px 0 4px;
                                }
                                .pc-txt-txt{
                                    padding:4px 0;
                                    
                                    color: #616166;
                                }
                            }
                        }
                        
                    }
                }
            }
            
        }
    }
}

/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shopcar{display: none;}
        .pc-shopcar .pc-shopwrap .pc-shop{width:45%;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info{padding:15px 10px;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-name{width:35%}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-txt{width:65%;word-break: break-all;}
        // .pc-shopcar .pc-shopwrap .pc-all{width:55%;padding:70px 10px 40px;}
        
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-shopcar{display: none;}
        .pc-shopcar .pc-shopwrap .pc-shop{width:45%;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info{padding:15px 10px;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-name{width:35%}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-txt{width:65%;word-break: break-all;}
        .pc-shopcar .pc-shopwrap .pc-all{width:55%;padding:20px 10px 0px;}
        
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            background: #fff;   
            padding: 20px 0 20px;
            .wap-info-shop{
                background: #f4f7fe;
                padding:0px 10px 0px;
                margin: 0 10px;
                .wap-info-title{
                    width: 100%;
                    
                    padding:10px;
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-info-box{
                    width: 100%;
                    padding:15px 20px 10px;
                    .wap-info-label{
                        
                        padding:0px 0 10px;
                    }
                    .wap-info-list{
                        padding:5px 0;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        .wap-info-name{
                            
                            width:25%;
                            color:#6e6e74;
                        }
                        .wap-info-txt{
                            
                            width:75%;
                        }
                    }
                }
            }
            .wap-info-logis{
                padding:0 10px;
                margin: 0;
                .wap-info-tit{
                    width: 100%;
                    font-size:20px;
                    padding:20px 0px 10px;
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-log{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-log-step{
                        width:100%;
                        .wap-log-box{
                            display: flex;
                            -webkit-box-orient: horizontal;
                            /deep/.van-step__title{
                                width:100%;
                                display: flex;
                                -webkit-box-orient: horizontal;
                                .wap-time{
                                    width:25%;
                                    
                                    color: #95959e;
                                }
                                .wap-txt{
                                    width:75%;
                                    .wap-txt-title{
                                        padding:0px 0 4px;
                                        
                                    }
                                    .wap-txt-txt{
                                        padding:4px 0;
                                        
                                        color: #616166;
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            background: #fff;   
            padding: 20px 0 20px;
            .wap-info-shop{
                background: #f4f7fe;
                padding:0px 10px 0px;
                margin: 0 10px;
                .wap-info-title{
                    width: 100%;
                    
                    padding:10px;
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-info-box{
                    width: 100%;
                    padding:15px 20px 10px;
                    .wap-info-label{
                        
                        padding:0px 0 10px;
                    }
                    .wap-info-list{
                        padding:5px 0;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        .wap-info-name{
                            
                            width:30%;
                            color:#6e6e74;
                        }
                        .wap-info-txt{
                            width:70%;
                        }
                    }
                }
            }
            .wap-info-logis{
                padding:0 10px;
                margin: 0;
                .wap-info-tit{
                    width: 100%;
                    font-size:20px;
                    padding:20px 0px 10px;
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-log{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-log-step{
                        width:100%;
                        .wap-log-box{
                            display: flex;
                            -webkit-box-orient: horizontal;
                            /deep/.van-step__title{
                                width:100%;
                                display: flex;
                                -webkit-box-orient: horizontal;
                                .wap-time{
                                    width:25%;
                                    
                                    color: #95959e;
                                }
                                .wap-txt{
                                    width:75%;
                                    .wap-txt-title{
                                        padding:0px 0 4px;
                                        
                                    }
                                    .wap-txt-txt{
                                        padding:4px 0;
                                        
                                        color: #616166;
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}
</style>